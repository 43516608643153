@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* overflow: hidden; */
}

.main-page,
.coming-page {
  width: 100%;
  background-image: url('./images/mediahero.webp');
  background-attachment: local;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.navbar {
  z-index: 2000;
}

.news-page {
  overflow: hidden;
}

.about-page,
.merch-page,
.events-page,
.trail-page {
  width: 100%;
  background-image: url('./images/About_bg.webp');
  background-attachment: local;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.who-mob {
  display: none;
}

.trail-info {
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 3.125rem;
  }
  .main-page {
    width: auto;
    background-image: url('./images/mediahero.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    background-position: 0 50px;
  }

  .waitlist {
    margin-left: 6rem;
    font-size: medium;
    margin-top: 2rem;
    padding: 1rem;
  }

  .who-mob {
    display: block;
  }
}
